import {useRoute, useRouter} from 'vue-router'

export default function queryParams() {
    const router = useRouter()
    const route = useRoute()

    const removeQueryParam = (param) => {
        if (route.query[param]) {
            const newQuery = {...route.query}
            delete newQuery[param]
            router.push({query: newQuery})
        }
    }

    return {
        removeQueryParam,
    }
}
